import permissions from '@/utils/constants/permissions'
import TopNavigation from '@/components/Page/TopNavigation'

export default {
    components: {TopNavigation},
    computed: {
        topNavLinks() {
            const disabled = !this.$route.params.id

            let arr = [
                {
                    title: 'Info',
                    disabled: disabled,
                    children: [
                        'ProjectNew',
                        'ProjectDetails',
                        'ProjectTeams',
                        'ProjectScopeWorkflow',
                        'ProjectContractWorkflow',
                        'ProjectAddTeam',
                        'ProjectContractsScopes',
                        'ProjectContractsScopesAdd',
                        'ProjectTemplateCollection',
                    ],
                    route: {
                        name: 'ProjectDetails',
                        params: {id: this.$route.params.id || 'disabled'},
                    },
                },
                {
                    title: 'Scopes',
                    disabled: disabled,
                    children: [
                        'ProjectScopes',
                        'ProjectScopesList',
                        'ProjectScopeEdit',
                        'ProjectScopeNew',
                    ],
                    route: {
                        name: 'ProjectScopesList',
                        params: {id: this.$route.params.id || 'disabled'},
                    },
                },
                {
                    title: 'Tasks',
                    disabled: disabled,
                    children: [
                        'ProjectTasks',
                        'ProjectTasksList',
                        'TasksChooseScope',
                        'TasksAssign',
                        'TasksConfirmData',
                        'TasksNotify',
                        'ProjectTaskView',
                        'TaskContracts',
                        'TaskContractsReviewDocument',
                        'TaskReviewApprove',
                        'TaskSign',
                        'TaskRequest',
                        'TaskAcknowledge',
                        'ProjectScopesTasksAssign',
                        'ProjectScopesTaskReview',
                        'ProjectScopesTaskMergeFields',
                        'ProjectScopesTaskConfirm'
                    ],
                    route: {
                        name: 'ProjectTasks',
                        params: {id: this.$route.params.id || 'disabled'},
                    },
                },
                {
                    title: 'Documents',
                    disabled: disabled,
                    permissions: [permissions.TASKS_AND_DOCUMENTS_VIEW],
                    route: {
                        name: 'ProjectDocuments',
                        params: {id: this.$route.params.id || 'disabled'},
                    },
                },
                {
                    title: 'Activity',
                    disabled: disabled,
                    route: {
                        name: 'ProjectActivity',
                        params: {id: this.$route.params.id || 'disabled'},
                    },
                },
            ]

            if (!this.$store.state.projects.permissions.view && !disabled) {
                arr = arr.filter(
                    (el) =>
                        !['ProjectScopesList', 'ProjectDetails'].includes(el.route.name),
                )
            }

            return arr
        },
    },
    async mounted() {
        await this.$nextTick(async () => {
            this.$route.params.id
                ? await this.$store.dispatch('fetchProjectPermissions', this.$route.params.id)
                : await this.$store.commit('setProjectPermissions')
        })
    },
}
